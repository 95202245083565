
import Credentials from '@/domain/model/user/Credentials.js';
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
import LabelDisplayRow from "@/portals/shared/cell/label/LabelDisplayRow.js";
import PasswordUpdateRow from "@/portals/shared/cell/password/PasswordUpdateRow.js";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";


import WebLogger from "@/domain/logger/WebLogger.js";
import MC from "@/domain/session/MC.js";
import RcTime from "@/domain/session/time/RcTime.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";
import LogoImageDisplayRow from "../../../shared/cell/image/logo/LogoImageDisplayRow.js";
import SignInModel from "./SignInModel.js";

export default class SignInModelUtils {

  static buildRowData(domain, panel, tableData) {
    var au = new SignInModelUtils(domain, panel, tableData);
    au.start();
  }

  constructor(panel, tableData) {
    this._panel = panel;
    this._tableData = tableData;
    this.MC = new MC();
    this._logger = new WebLogger(window.console, true);
  }

  panel() {
    return this._panel;
  }

  credentials() {
    var panel = this.panel();
    var model = panel.model();
    return model.credentials();
  }

  logger() {
    return this._logger;
  }

  start() {
    var model = this.panel().model();
    for (var spacers = 0; spacers < 2; spacers++) {
      this.addRow(new SpacerDisplayRow());
    }

    var signInOrWelcomeRow = new StringDisplayRow().withValue("Sign in").withAlignLeft().withStyle("rc-font-extra-large");
    var continueorEmailRow = new StringDisplayRow().withValue("To continue with ReadyChek").withAlignLeft();

    if (this.credentials().hasEmail()) {
      signInOrWelcomeRow.withValue("Welcome");
      continueorEmailRow.withValue(this.credentials().email());
    }
    var mainKids = [];
    var hint = model.error().hint;
    if (model.isStateLoading()) {
      mainKids = [
        new SpacerDisplayRow(),
        new SpacerDisplayRow(),
        new StringDisplayRow().withValue(model.status()).withStyle("rc-font-large"),
      ];
    } else {
      mainKids = [
        new StringUpdateRow().withName("Email").withMandatoryValue(true).withField(Credentials.FIELDS.Email).withDefaultValue(this.credentials().email()),
        new PasswordUpdateRow().withName("Password").withHint("Forgot your password?").withHintUrl("/recovery").withMandatoryValue(true).withField(Credentials.FIELDS.Password).withDefaultValue(this.credentials().password()),
        new RowDisplayRow()
          .withChildren([
            new BoxDisplayRow()
              .withWidth(15)
              .withChildren([
                new ButtonDisplayRow().withWidth(15).withName("Sign In").withBackgroundPrimaryAction().withField(SignInModel.Fields.SignInButton),
              ]),
          ]),
      ];
    }

    let ads = [];
    let adsBox = new BoxDisplayRow();
    let date = RcTime.time().now()
    if (date.month().value() == 0 && date.day().value() < 9) {
      adsBox.addChild(new RowDisplayRow()
        .withChild(new BoxDisplayRow().withWidth(15).withBorder().withEven(true)
          .withChild(new LabelDisplayRow().withLabel("Landscape Ontario Congress Connect").withFontSize("xxxlarge").withAlignCentre())
          .withChild(new StringDisplayRow().withValue("Come visit us at Landscape Ontario Congress Connect Jan 7 and 8!"))
          .withChild(new StringDisplayRow().withValue("Toronto Congress Centre 650 Dixon Road, Toronto, Ontario M9W 1J1, Canada").withFontSize("xsmall"))
          .withChild(new StringDisplayRow().withValue("We'll be in the education section at booth E8 with TriShield Consulting"))
          .withChild(new StringDisplayRow().withValue("If you'd like free tickets please contact us."))
        ))
    } else {
      adsBox.addChild(new SpacerDisplayRow());
      adsBox.addChild(new SpacerDisplayRow());
      adsBox.addChild(new SpacerDisplayRow());
      adsBox.addChild(new SpacerDisplayRow());
      adsBox.addChild(new SpacerDisplayRow());
      adsBox.addChild(new SpacerDisplayRow());
    }
    ads.push(adsBox);

    this.addRow(new RowDisplayRow()
      .withChildren([
        new BoxDisplayRow()
          .withWidth(3)
          .withChildren([]),
        new BoxDisplayRow()
          .withWidth(9)
          .withPrimaryBackgroundColour()
          .withChildren([
            ...ads,
            new RowDisplayRow()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(13)
                  .withChildren([
                    new StringDisplayRow().withValue(hint + " ")
                      .withStyleEven()
                      .withStyle("rc-title-error-left rc-font-regular-large"),
                  ]),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
              ]),
            new RowDisplayRow()
              .withBorder()
              .withChildren([
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
                new BoxDisplayRow()
                  .withWidth(7)
                  .withChildren([
                    new LogoImageDisplayRow(),
                    signInOrWelcomeRow,
                    continueorEmailRow,
                  ]),
                new BoxDisplayRow()
                  .withWidth(6)
                  .withChildren(
                    mainKids,
                  ),
                new BoxDisplayRow()
                  .withWidth(1)
                  .withChildren([]),
              ]),
          ]),
        new BoxDisplayRow()
          .withWidth(3)
          .withChildren([]),
      ]));

    if (!model.isStateLoading()) {
      this.addRow(new RowDisplayRow()
        .withChildren([

          new BoxDisplayRow()
            .withWidth(3)
            .withChildren([]),
          new BoxDisplayRow()
            .withWidth(9)
            .withBorder()
            .withPrimaryBackgroundColour()
            .withChildren([
              new SpacerDisplayRow(),
              new RowDisplayRow()
                .withChildren([
                  new BoxDisplayRow()
                    .withWidth(1)
                    .withChildren([]),
                  new BoxDisplayRow()
                    .withWidth(7)
                    .withChildren([
                      new StringDisplayRow().withValue("New? If you don't have an account yet click the Create Account button")
                    ]),
                  new BoxDisplayRow()
                    .withWidth(6)
                    .withChildren([
                      new ButtonDisplayRow().withWidth(15).withName("Create Account").withBackgroundNoAction().withField(SignInModel.Fields.RegistrationButton),
                    ]),
                  new BoxDisplayRow()
                    .withWidth(1)
                    .withChildren([]),
                ]),
              new SpacerDisplayRow(),
            ]),
          new BoxDisplayRow()
            .withWidth(3)
            .withChildren([]),
        ]));
    }
  }

  addRow(row) {
    this._tableData.push(row);
  }
}
/*
<!--
this.addRow(
  new RowDisplayRow()
  .withChildren([

    new BoxDisplayRow()
      .withWidth(3)
      .withChildren([]),
    new BoxDisplayRow()
      .withWidth(9)
      .withPrimaryBackgroundColour()
      .withBorder(true)
      .withBorderStyleLarge()
      .withChildren([
        new ButtonDisplayRow().withWidth(15).withName("Create Account").withBackgroundNoAction().withField(SignInModel.Fields.RegistrationButton),
                     ]),
    new BoxDisplayRow()
      .withWidth(3)
      .withChildren([
      ]),
    ])); --!>
    */