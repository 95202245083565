<template>

  <Box>
    <Row>
      <Column :width="15">
        <Box :key="redraw">
          <Row v-for="(row, rowIndex) in tableData" :key="rowIndex">

            <CellFactory :rowData="row.data" 
              :showMissingRequirements="showMissingRequirements"
              v-on:withValue="withValue" 
              v-on:withEnterPressed="withEnterPressed"
              v-on:withButtonPressed="withButtonPressed" />

          </Row>
        </Box>
      </Column>
    </Row>
  </Box>

</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import SignInModel from "./SignInModel.js";
import Credentials from "@/domain/model/user/Credentials.js";

import TitleDisplayRow from '@/portals/shared/cell/title/TitleDisplayRow.js';
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import DateUtils from '@/utils/DateUtils.js';
import RcResponseEvent from '@/domain/model/events/RcResponseEvent.js';
import UserEvents from '@/domain/model/user/UserEvents.js';

export default {
  name: "sign-in-view",
  components: {
    Column, Box, Row,
    CellFactory,
  },
  props: {
  },
  data() {
    return {
      redraw: 1,

      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      CellFactory: CellFactory,
      cookie: null,
      credentials: null,

      tableData: [],
      addModel: null,
      timeout: null,
      ready: false,

      showSavingDialog: false,
      showSessionTimeoutDialog: false,
      showMissingRequirements: false,
      
      TitleDisplayRow: TitleDisplayRow,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
      'AuthStore_socketError',
    ]),
  },
  watch: {
    AuthStore_socketError() {
      if (this.AuthStore_socketError != null) {
        this.model()
          .withErrorUnableToConnectToApi();
        this
          .populateData();
      }
    },

    auth_connected() {
      if (this.auth_connected) {
        this.model().send();
      }
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      if (this.signin_event) {
        var event = new RcResponseEvent(this.domain, this.signin_event);
        if (event.error()) {
          this.model().withError(UserEvents.SignIn.Errors.find(event.error()));
          this.populateData();
        } else {
          ConnectionUtils.createDomain(this, this.callback);
          
          var cookie = { 
            email: this.credentials.email(), 
            password: this.credentials.password(),
          };

          if (this.remember) {
            cookie['auto'] = true;
          }
          
          cookie['time'] = DateUtils.now();
          cookie['User'] = this.domain.session().user().id();
          cookie['Company'] = "";
          
          var cookieString = JSON.stringify(cookie);
          window.localStorage.readyChek = cookieString;
          this.$cookie.set("readychek.ca", cookieString, 365);

          this.AuthStorage_updateCompany(null);
          
          var employees = this.domain.employees().findByUserId(this.domain.session().user().id());
          
          if (employees.size() > 1) {
            this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.COMPANY.SELECT });
          } else {
            var session = this.domain.session();
            var employee = employees.first();
            var company = employee.company().find();
            session.with(company);
            session.with(employee);
            
            cookie['Company'] = company.id();
            cookieString = JSON.stringify(cookie);
            window.localStorage.readyChek = cookieString;
            this.$cookie.set("readychek.ca", cookieString, 365);

            var roles = session.employee().roles().find();
            if (roles.isAdministrator()) {
              ConnectionUtils.withAdminCompany(this);
              this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.ADMIN.COMPANY.LIST });
              // this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.ADMIN.BLANK });
            } else {
              this.AuthStorage_updateCompany(company);
              let listPath = StringUtils.format(ConstUtils.ROUTES.HOME_PATH, [company.idEncoded()]);
              this.$router.push({
                path: listPath
              });
            }
          }
        }
      }
    }
  },
  mounted: function () {
    this.AuthStorage_updateCompany(null);
    var cookieString = this.$cookie.get("readychek.ca");
    if (cookieString) {
      this.cookie = JSON.parse(cookieString);
      // this.$cookie.delete("readychek.ca");
    }
    this.start();
  },
  methods: {
    ...mapActions([
      'AuthStorage_updateCompany',
      'addListenerAction',
      'removeListenerAction',
      'updateDomain',
      "authClear",
    ]),

    start: function () {
      this.credentials = new Credentials(null);
      if (this.cookie) {
        this.credentials
         .withEmail(this.cookie.email)
         .withPassword(this.cookie.password);
      }
      this.addModel = new SignInModel(this, this.credentials);
      this.model().start();
      this.tableData = this.model().tableData();
      this.ready = true;
    },

    callback: function (msg) {
      this.model().withStatus(msg);
      this.reloadData();
    },

    model: function () {
      return this.addModel;
    },

    withValue: function (kvp) {
      this.model().withValue(kvp);
    },

    withEnterPressed: function (keyData) {
      this.model().withEnterPressed(keyData);
    },

    withButtonPressed: function (buttonData) {
      this.model().withButtonPressed(buttonData);
    },

    populateData: function () {
      this.tableData = [];
      this.reloadData();
    },

    reloadData: function () {
      this.model().populateData();
      this.tableData = this.model().tableData();
      this.redraw += 1;
    },

    send() {
      this.model().sendIfPossible();
    },

    sendDelayed() {
      setTimeout(this.send, 500);
    },
  },
}
</script>