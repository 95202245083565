
import SignInModelUtils from "./SignInModelUtils.js";

import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import WebLogger from "@/domain/logger/WebLogger";
import ConstUtils from '@/utils/ConstUtils.js';

import EventUtils from '@/utils/EventUtils.js';

//import Company from '@/domain/model/company/Company.js';
import UserEvents from '@/domain/model/user/UserEvents.js';
import EventsDomain from '@/domain/model/events/EventsDomain.js';
import { uuid } from 'vue-uuid'

export default class SignInModel {
  
  static Fields = {
      RegistrationButton: "register",
      SignInButton: "signIn",
  }
  
  static Values = {
      States: {
        Ready: "ready",
        SigningIn: "signing-in",
      }
  }
  
  constructor(panel, credentials) {
    this._panel = panel;
    this._state = SignInModel.Values.States.Ready;
    this._credentials = credentials;
    this._tableData = [];
    this._domain = this.panel().domain;
    this._logger = new WebLogger(window.console, true);
    this._error = {
      messageId: "",
      hint: ""
    };
    this._timer = null;
    this._status = "";
    this._checklistDataLoaded = false;
    this._scheduleDataLoaded = false;
    if (!StringUtils.isEmpty(this._panel.$route.query.password)) {
      this.credentials().withPassword(this._panel.$route.query.password);
    }
    if (!StringUtils.isEmpty(this._panel.$route.query.email)) {
      this.credentials().withEmail(this._panel.$route.query.email);
    }
    this.logger().info("Start: {0}", [JSON.stringify(this._credentials)]);
  }
  
  start() {
    this.populateData();
  }
  
  populateData() {
    this._tableData = [];
    var newData = []
    this.buildRowData(newData)
    this._tableData = newData;
    return this;
  }
  
  buildRowData(newData) {
    var utils = new SignInModelUtils(this.panel(), newData);
    utils.start();
  }
  
  domain() {
    return this._domain;
  }
  
  panel() {
    return this._panel;
  }
  
  logger() {
    return this._logger;
  }
  
  credentials() {
    return this._credentials;
  }
  
  withValue(kvp) {
    this.logger().info("SignInModel FieldValue Received: {0}", [JSON.stringify(kvp)]);
    this.panel().showMissingRequirements = false;
    if (!kvp) {
      return;
    }

    var field = kvp['field'];
    var fieldValue = StringUtils.trim(kvp['value']);
    var objectId = kvp['id'];
    if (!objectId) {
      return;
    }

    this._credentials.put(field, fieldValue);      
  }
  
  withEnterPressed(keyData) {
    this.logger().info("SignInModel EnterPressed: {0}", [JSON.stringify(keyData)]);
    if (!keyData) {
      return;
    }
    if (keyData["field"] === "password") {
      this.handleSignInAction();
    }
  }
  
  /**
   * Handle buttons being pressed.
   */
  withButtonPressed(buttonData) {
    this.logger().info("SignIn ButtonPressed: {0}", [JSON.stringify(buttonData)]);
    if (!buttonData) {
      return;
    }
    var field = buttonData['field'];
    if (!field) {
      return;
    }
    if (StringUtils.e(SignInModel.Fields.SignInButton, field)) {
      this.handleSignInAction();
    }
    if (StringUtils.e(SignInModel.Fields.RegistrationButton, field)) {
      this.panel().$router.replace(
        { name: this.panel().$route.query.redirectTo || ConstUtils.ROUTES.SIGNUP }
        );
    }
  }
  
  handleSignInAction() {
    if (!this.areRequirementsMet()) {
      this.panel().showMissingRequirements = true;
      return;
    }
    
    this._state = SignInModel.Values.States.SigningIn;
    this.withError({
      messageId: "",
      hint: ""
    });
    this.panel().sendDelayed();
  }

  withStatus(status) {
    this._status = status;
  }

  status() {
    return this._status;
  }

  sendIfPossible() {
    this._state = SignInModel.Values.States.SigningIn;
    if (this.panel().auth_connected) {
      this.send();
    } else {
      this.panel().authClear();
      try {
        this.panel().$connect();
      } catch (e) {
        this.error = {
            messageId: "NoConnection",
            hint: "It appears that ReadyChek.cloud is not reachable at the moment"
        }
      }
    }
  }

  send() {
    var error =  {
      messageId: "",
      hint: ""
    }
    this.withError(error);
    
    const email = this.credentials().email();
    const password = this.credentials().password();

    this._state = SignInModel.Values.States.SigningIn;
    const requestEvent = UserEvents.SignIn.Request(email, ContentUtils.decode(password));
    requestEvent['maker'] = window.navigator.userAgent;
    EventsDomain.initEvent(requestEvent, null, uuid.v1(), this.panel().auth_client.deviceId, this.panel().auth_client.version);
    EventUtils.Send.event(this.panel().$socket, requestEvent);
  }

  tableData() {
    return this._tableData;
  }
  
  isStateReady() {
    return StringUtils.e(this._state, SignInModel.Values.States.Ready);
  }
  
  isStateLoading() {
    return StringUtils.e(this._state, SignInModel.Values.States.SigningIn);
  }

  withError(error) {
    this._error = error;
    this._state = SignInModel.Values.States.Ready;
    return this;
  }

  withErrorUnableToConnectToApi() {
    var error =  {
      messageId: "NoConnectionToApi",
      hint: "It appears that ReadyChek.cloud's API is not reachable, please try again"
    }
    this.withError(error);
    return this;
  }

  error() {
    return this._error;
  }
  
  areRequirementsMet() {
    var met = true;
    if (met && StringUtils.isEmpty(this.credentials().email())) {
      met = false
    }
    if (met && StringUtils.isEmpty(this.credentials().password())) {
      met = false
    }
    return met;
  }
  
}







